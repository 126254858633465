import { useState } from "react";
import Input from "../../components/preview-input/input";
import usePreviewStore from "../../store/preview-store";

function GutterTypeSelection() {
  const { previewData,  setFormData } = usePreviewStore();
  const selectedGutterType = previewData.gutter_type;
  const handleInputChange = (value) => {
    setFormData({ 
      ...previewData,
      gutter_type: value
     });
  };
  const handleInputChangeInput = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value
    });
  };

  console.log(previewData)
  return (
    <div className="k-d-grid k-grid-cols-5 k-mt-10 k-pr-4">
      <h4 className="k-m-0 k-font-light k-pt-2">Gutter Type:</h4>
      
      <label className="k-m-0 k-font-light k-pt-2">
        <input
          style={{ marginRight: "10px" }}
          type="radio"
          name="gutterType"
          value="FS"
          checked={selectedGutterType === "FS"}
          onChange={() => handleInputChange("FS")}
        />
        FS
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
          style={{ marginRight: "10px" }}
          type="radio"
          name="gutterType"
          value="k-Style"
          checked={selectedGutterType === "k-Style"}
          onChange={() => handleInputChange("k-Style")}
        />
        k-Style
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
          style={{ marginRight: "10px" }}
          type="radio"
          name="gutterType"
          value="HR"
          checked={selectedGutterType === "HR"}
          onChange={() => handleInputChange("HR")}
        />
        HR
      </label>

      <div className="k-display-flex k-gap-2">
        <h4 className="k-m-0 k-font-light k-pt-2">Color:</h4>
        <div className="k-pos-relative k-top-1">
          <Input value={previewData.color} onChange={handleInputChangeInput('color')}/>
        </div>
      </div>
    </div>
  );
}

export default GutterTypeSelection;
