import { useState, useEffect } from "react";
import Logo from "../logo/logo";
import styles from "./SplashScreen.module.scss";
import logo from "../../assets/images/logo-530-179.png";
export default function SplashScreen({ sx, ...other }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <div className={`${styles.bg_image_container} k-d-flex k-justify-content-center k-align-items-center k-h-screen`}>
      <div className="k-pb-30 k-px-20">
        <Logo logoUpdate={logo} />
      </div>
    </div>
  );
}
