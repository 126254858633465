import React from 'react'
import drawingfour from '../../assets/images/drawing-four.png';
import drawingone from '../../assets/images/drawing-one.png';
import drawingthree from '../../assets/images/drawing-three.png';
import drawingtwo from '../../assets/images/drawing-two.png';
const DrawingBox = () => {
  return (
    <div className="k-pl-6 k-pb-4 k-pt-2 k-display-flex" style={{flexDirection:"column" , justifyContent:"space-between"}}>

    <div>
      <div className="k-text-center">
        <h2>
          <span style={{ borderBottom: "2px solid black" }}>Drawing</span>
        </h2>
      </div>


      <div className='k-pos-relative k-top-1 k-text-center '>
        <img alt='drawing-one' src={drawingone} />
      </div>

      <div className='k-pos-relative k-top-1 k-text-center k-display-flex k-justify-content-center k-mt-2'>
        <div>
          <img alt='drawing-two' src={drawingtwo} />
        </div>
        <div className="k-ml-4">
          <img alt='drawing-three' src={drawingthree} />
        </div>
      </div>
    </div>
    <div className='k-pos-relative k-top-1 k-text-center'>
      <div>
        <img alt='drawing-four' src={drawingfour} />
        <h4>(Font of House)</h4>
      </div>
    </div>
  </div>
  )
}

export default DrawingBox