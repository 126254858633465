import React from "react";
import Input from "../../components/preview-input/input";
import SizeSelection from "./size-selection";
import FasciaReplacementSelection from "./fascia-replacement-selection";
import usePreviewStore from "../../store/preview-store";

const Fascia = () => {
  const { previewData, setFormData } = usePreviewStore();
  const handleInputChange = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };
  return (
    <div className="k-px-2 k-border-solid k-border k-border-black k-mt-0">
      <FasciaReplacementSelection />
      <SizeSelection />

      <div className="k-d-flex k-align-content-between k-grid-cols-1 k-mt-4">
        <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-30">
          Type:(e.x: pine, cedar)
        </h4>
        <div className="k-flex-basis-auto k-d-flex k-m-0 k-w-40  k-font-light k-mt-0">
          <Input
            value={previewData.fascia_type}
            onChange={handleInputChange("fascia_type")}
          />
        </div>
        <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-40 k-pl-12">
          Painted / Metal Wrapped / Other
        </h4>
        <div className="k-flex-basis-auto k-d-flex k-m-0 k-w-40  k-font-light k-mt-0 k-pl-8">
          <Input
            value={previewData.fascia_other}
            onChange={handleInputChange("fascia_other")}
          />
        </div>
      </div>

      <div className="k-d-flex  k-grid-cols-1 k-mt-4 k-mb-4">
        <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-30">
          Total FT. of new wrap:{" "}
        </h4>
        <div className="k-flex-basis-auto k-d-flex k-m-0 k-w-40  k-font-light k-mt-0">
          <Input
            value={previewData.fascia_wrap}
            onChange={handleInputChange("fascia_wrap")}
          />
        </div>
        <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0  k-pl-12">
          (* We will put up primed, Cust. to Paint.)
        </h4>
      </div>
    </div>
  );
};

export default Fascia;
