import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import Input from "../../components/preview-input/input";
import FilterColors from "./filter-colors";
import FilterProfile from "./filter-profile";
import MainDetails from "./main-details";
import OrderProfile from "./order-profile";
import Story from "./story";
import { useNavigate } from "react-router-dom";
import DrawingBox from "./drawing-box";
import Extensions from "./extensions";

const OrderSheet = () => {
  const navigate = useNavigate();
  return (
    <div className="">
      <h2 className="k-py-4 k-font-bold">Preview</h2>
      <div className="k-rounded-lg k-p-4" style={{ background: "#FFFFFF" }}>
        <div
          className=" k-px-8 k-border-solid k-border k-border-black"
          style={{ width: "30%" }}
        >
          <h2 className="k-m-0 k-py-3">LeafFilter Order Sheet</h2>
        </div>
        <div className="k-border-solid k-border k-border-black k-mt-1">
          <MainDetails />
        </div>
        <div className="k-border-solid k-border k-border-black">
          <OrderProfile />
        </div>
        <div className="k-border-solid k-border k-border-black">
          <FilterProfile />
        </div>
        <div className="k-px-2 k-border-solid k-border k-border-black  k-d-grid k-grid-cols-2">
          <Story />

          <FilterColors />
        </div>

        <div className="k-px-2 k-border-solid k-border k-border-black k-d-grid k-grid-cols-2">
          <Extensions />

          {/* second next section */}
          <DrawingBox />
        </div>
        <div className=" k-px-2 k-border-solid k-border k-border-black">
          <h4 className="k-m-0 k-p-3">
            <u>Note : </u>
          </h4>
        </div>
      </div>
      <div className="k-w-full k-d-flex k-justify-content-end k-my-8">
        <Button
          onClick={() => navigate(-1)}
          primary={true}
          className="k-button k-mr-2"
          size="large"
        >
          Cancel
        </Button>
        <Button primary={true} className="k-button-green k-mr-2" size="large">
          Submit
        </Button>
      </div>
    </div>
  );
};

export default OrderSheet;
