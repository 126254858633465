import React from "react";
import map from "../../assets/images/map.png";
import phone from '../../assets/images/phone.png'
const SingleLeadTabContentCard = () => {
  return (
    <div
      className="k-rounded-lg k-elevation-1 k-p-4 k-mb-4 k-cursor-pointer"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <h2>9:00 AM</h2>
      <h1 className="k-m-0 k-pb-2 k-font-normal k-text-uppercase">Thomas Razin</h1>
      <div className="k-mt-4 k-display-flex">
        <div className="k-mr-6">
          <img alt="map" src={map} />
        </div>
        <div>
          <h2
            style={{ lineHeight: "30px", color: "#337AB7" }}
            className="k-m-0 k-font-normal k-text-uppercase"
          >
            4212 LAKE VIEW DR S <br />
            ELLENTON, FL 34222
          </h2>
        </div>
      </div>
      <div className="k-mt-10 k-display-flex">
        <div className="k-mr-6">
          <img alt="phone" src={phone} />
        </div>
        <div>
          <h2
            style={{ lineHeight: "30px", color: "#337AB7" }}
            className="k-m-0 k-font-normal k-pt-1"
          >
            9089435102
          </h2>
        </div>
      </div>
      <div className="k-ml-6 k-mb-12">
      <h4 className="k-text-uppercase">Id: 11945650</h4>
      <h4 className="k-text-uppercase">Mkt Code: 41PQS</h4>
      <h4 className="k-text-uppercase">Email Address: trazin143@gmail.com</h4>
      <h4 className="k-text-uppercase">Calendar Notes:</h4>
      </div>
    </div>
  );
};

export default SingleLeadTabContentCard;
