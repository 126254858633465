import { useState } from "react";
import usePreviewStore from "../../store/preview-store";

function GutterSizeSelection() {
  const { previewData,  setFormData } = usePreviewStore();
  const selectedGutterSize = previewData.gutter_size;
  const handleInputChange = (value) => {
    setFormData({ 
      ...previewData,
      gutter_size: value
     });
  };
  return (
    <div className="k-display-flex k-mt-2">
      <h4 className="k-m-0 k-font-light k-pt-2">Gutter Size:</h4>
      
      <div className="k-display-flex k-ml-13">
        <label className="k-m-0 k-font-light k-pt-2 k-mr-4">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterSize"
            value="4"
            checked={selectedGutterSize === "4"}
            onChange={() => handleInputChange("4")}
          />
          4
        </label>

        <label className="k-m-0 k-font-light k-pt-2 k-mr-4">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterSize"
            value="4.5"
            checked={selectedGutterSize === "4.5"}
            onChange={() => handleInputChange("4.5")}
          />
          4.5
        </label>

        <label className="k-m-0 k-font-light k-pt-2 k-mr-4">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterSize"
            value="6"
            checked={selectedGutterSize === "6"}
            onChange={() => handleInputChange("6")}
          />
          6
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterSize"
            value="7"
            checked={selectedGutterSize === "7"}
            onChange={() => handleInputChange("7")}
          />
          7
        </label>
      </div>
    </div>
  );
}

export default GutterSizeSelection;
