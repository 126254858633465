import { useState } from "react";
import Input from "../../components/preview-input/input";
import usePreviewStore from "../../store/preview-store";

function SizeSelection() {
  const { previewData, setFormData } = usePreviewStore();
  const selectedSize = previewData.fascia_size;
  const handleInputChange = (value) => {
    setFormData({
      ...previewData,
      fascia_size: value,
    });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };
  return (
    <div className="k-d-flex k-grid-cols-1 k-mt-4">
      <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-16">
        Size:
      </h4>

      <div className="k-d-flex k-gap-2">
        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0">
          <input
            style={{ marginRight: "10px" }}
            type="radio"
            name="size"
            value="1x4"
            checked={selectedSize === "1x4"}
            onChange={() => handleInputChange("1x4")}
          />
          1 x 4
        </label>

        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0">
          <input
            style={{ marginRight: "10px" }}
            type="radio"
            name="size"
            value="1x6"
            checked={selectedSize === "1x6"}
            onChange={() => handleInputChange("1x6")}
          />
          1 x 6
        </label>

        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0">
          <input
            style={{ marginRight: "10px" }}
            type="radio"
            name="size"
            value="1x8"
            checked={selectedSize === "1x8"}
            onChange={() => handleInputChange("1x8")}
          />
          1 x 8
        </label>

        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0">
          <input
            style={{ marginRight: "10px" }}
            type="radio"
            name="size"
            value="1x10"
            checked={selectedSize === "1x10"}
            onChange={() => handleInputChange("1x10")}
          />
          1 x 10
        </label>

        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-17">
          <input
            style={{ marginRight: "10px" }}
            type="radio"
            name="size"
            value="Other"
            checked={selectedSize === "Other"}
            onChange={() => handleInputChange("Other")}
          />
          Other
        </label>

        {selectedSize === "Other" && (
          <div className="k-flex-basis-auto k-d-flex k-m-0 k-w-30 k-font-light k-mt-0">
            <Input
              value={previewData.fascia_other_size}
              onChange={handleInputChange2("fascia_other_size")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SizeSelection;
