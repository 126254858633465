import React from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CiEraser } from "react-icons/ci";
import { BsFloppy } from "react-icons/bs";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./ActionBar.module.scss";
import { useNavigate } from "react-router-dom";

const ActionsBar = () => {
  const navigate=useNavigate()
  return (
    <div className={`k-display-flex k-justify-content-between ${styles.k_action_button_container}`}>
      <Button onClick={()=>navigate(-1)} primary={true} className="k-button-red k-rounded-lg" size="large">
        <IoIosCloseCircleOutline  className="k-mt-2"/>
      </Button>
      <Button primary={true} className="k-rounded-lg" size="large">
        <CiEraser className="k-mt-2" />
      </Button>
      <Button primary={true} className="k-rounded-lg" size="large">
        <BsFloppy className="k-mt-2" />
      </Button>
    </div>
  );
};

export default ActionsBar;
