import { useState } from "react";
import Input from "../../components/preview-input/input";
import usePreviewStore from "../../store/preview-store";

function SoffitReplacementSelection() {
  const { previewData,  setFormData } = usePreviewStore();
  const soffitReplacement = previewData.soffit_replacement;
  const handleInputChange = (value) => {
    setFormData({ 
      ...previewData,
      soffit_replacement: value
     });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };
  return (
    <div className="k-d-flex k-grid-cols-1 k-mt-4 k-px-2">
      <h4 className="k-flex-basis-auto k-d-flex k-m-0 k-w-30">Soffit Replacement:</h4>

      <div className="k-display-flex k-gap-2">
        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="soffitReplacement"
            value="Full"
            checked={soffitReplacement === "Full"}
            onChange={() => handleInputChange("Full")}
          />
          Full
        </label>


        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="soffitReplacement"
            value="Partial"
            checked={soffitReplacement === "Partial"}
            onChange={() => handleInputChange("Partial")}
          />
          Partial
        </label>

        <h4 className="k-flex-basis-auto k-d-flex k-m-0 k-w-30 k-pl-9 k-font-light">Total Footage:</h4>

        <div className="k-flex-basis-auto k-d-flex k-m-0 k-w-30 k-font-light k-mt-0">
          <Input
          value={previewData.soffit_total_footage}
          onChange={handleInputChange2("soffit_total_footage")}
          />
        </div>
      </div>
    </div>
  );
}

export default SoffitReplacementSelection;
