import React from 'react'
import Input from '../../components/preview-input/input'
import ColorSelection from './color-selection'
import ModularVersaSelection from './modular-versa-selection'
import GutterOpeningWidthSelection from './gutter-opening-width-selection'
import PartialSelection from './partial-selection'
import ExistingProtectionSelection from './existing-protection-selection'
import FastenerSelection from './fastener-selection'
import FastenerTypeSelection from './fastener-type-selection'
import YesNoColorSelection from './yes-no-color-selection'
import RoofingTypeSelection from './roofing-type-selection'
import RoofTypeSelection from './roof-type-selection'

const FilterColors = () => {
  return (
    <div className="k-pl-6 k-pb-4 k-pt-2">
   
 <ColorSelection/>

    <h4 style={{borderBottom:"2px solid black"  , width:"fit-content"}}>
      LeafFilter Color
    </h4>

   <ModularVersaSelection/>

    <div className="k-display-flex k-gap-2 k-mt-4">
      <h4 className="k-m-0 k-font-light k-pt-2">Existing Gutter Color:</h4>
      <div className="k-pos-relative k-top-1">
        <Input />
      </div>
    </div>


  <GutterOpeningWidthSelection/>
   

    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">Total Footage</h4>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{width:"100px"}}>
        <Input />
      </div>
    <PartialSelection/>
    </div>



    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">Clean, Seal,  Rainforce</h4>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{width:"100px"}}>
        <Input />
      </div>
      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4">
      ft.   (circle if CSR Only)
      </h4>
    </div>


   


    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2"># of Inside Corners</h4>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{width:"100px"}}>
        <Input />
      </div>
      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4">
      # of Outside Corners
      </h4>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{width:"100px"}}>
        <Input />
      </div>
    </div>



    <div className="k-display-flex k-mt-1">
     <ExistingProtectionSelection/>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{width:"100px"}}>
        <Input />
      </div>
    </div>

    <FastenerSelection/>

   <FastenerTypeSelection/>


  <YesNoColorSelection/>



    <RoofingTypeSelection/>

    <RoofTypeSelection/>


  





  </div>
  )
}

export default FilterColors