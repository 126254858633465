import { useState } from "react";
import Input from "../../components/preview-input/input";

function YesNoColorSelection() {
  const [isSelected, setIsSelected] = useState("Yes");

  return (
    <div className="k-display-flex k-mt-1">
      <div className="k-pos-relative k-top-1" style={{ width: "100px" }}>
        <Input />
      </div>

      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4">
        <label>
          <input
            type="radio"
            name="selection"
            value="Yes"
            checked={isSelected === "Yes"}
            onChange={() => setIsSelected("Yes")}
            style={{ marginRight: "10px" }}
          />
          Yes
        </label>
        <label style={{ marginLeft: "8px" }}>
          <input
            type="radio"
            name="selection"
            value="No"
            checked={isSelected === "No"}
            onChange={() => setIsSelected("No")}
            style={{ marginRight: "10px" }}
          />
          No
        </label>
        &nbsp; Color:
      </h4>

      <div className="k-pos-relative k-top-1 k-ml-3" style={{ width: "100px" }}>
        <Input />
      </div>
    </div>
  );
}

export default YesNoColorSelection;
