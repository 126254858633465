import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import React from "react";

const HomeToolBar = () => {
  return (
    <div>
      <h3>LEADS FOR:</h3>
      <h1>Tuesday 10/15/2024</h1>
      <div className="k-my-6">
        <Button
          primary={true}
          className="k-button-blue"
          size="large"
        >
          Refresh Leads
        </Button>
      </div>
      <div className="k-my-6">
        <Button
          primary={true}
          className="k-button-blue"
          size="large"
        >
          Create Self Gen
        </Button>
      </div>
      <div className="k-my-6">
        <Button
          primary={true}
          className="k-button-blue"
          size="large"
        >
          Create Rehash
        </Button>
      </div>
      <div className="k-display-flex k-align-items-center">
        <div>
          <h4 className="k-font-medium">JOB NUMBER:</h4>
        </div>
        <div className="k-ml-2">
          <Input />
        </div>
        <div className="k-ml-2">
          <Button
            primary={true}
            className="k-button-blue"
            size="large"
          >
            Schedule Unscheduled Job
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HomeToolBar;
