import React from "react";
import MainDetails from "./main-details";
import Input from "../../components/preview-input/input";
import Drawing from "./drawing";
import MeasurementsLeft from "./measurements-left";
import MeasurementsRight from "./measurements-right";
import Fascia from "./fascia";
import Soffit from "./soffit";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";

const Preview = () => {
  const navigate=useNavigate()

  return (
    <div className="">
      <h2 className="k-py-4 k-font-bold">Preview</h2>
      <div className="k-rounded-lg k-p-4" style={{ background: "#FFFFFF" }}>
        <div
          className=" k-px-8 k-border-solid k-border k-border-black"
          style={{ width: "60%" }}
        >
          <h2 className="k-text-right k-m-0 k-py-3">
            Gutter / Fascia / Soffit Prep Form
          </h2>
        </div>
        <div className="k-border-solid k-border k-border-black k-mt-1">
          <MainDetails />
        </div>
        <div className=" k-px-4 k-border-solid k-border k-border-black k-mt-1">
          <h2 className="k-m-0 k-py-3">
            Measurements (list each run separate and round up to the nearest
            foot)
          </h2>
        </div>

        <div className="k-px-2 k-border-solid k-border k-border-black k-mt-1 k-d-grid k-grid-cols-2">
          <MeasurementsLeft />

          <MeasurementsRight />
        </div>

        <Fascia />

        <Soffit />
      </div>
      <div className="k-w-full k-d-flex k-justify-content-end k-my-8">
     <Button onClick={() => navigate(-1)} primary={true} className="k-button k-mr-2" size="large">
         Cancel
        </Button>
     <Button  primary={true} className="k-button-green k-mr-2" size="large">
         Submit
        </Button>
     </div>
    </div>
  );
};

export default Preview;
