import { useState } from "react";

function ExistingProtectionSelection() {
  const [existingProtection, setExistingProtection] = useState("Yes");

  return (
    <h4 className="k-m-0 k-font-light k-pt-2">
      Existing Protection:&nbsp;&nbsp;
      <label style={{ marginRight: "8px" }}>
        <input
          type="radio"
          name="existingProtection"
          value="Yes"
          checked={existingProtection === "Yes"}
          onChange={() => setExistingProtection("Yes")}
          style={{ marginRight: "4px" }}
        />
        Yes
      </label>
      <label>
        <input
          type="radio"
          name="existingProtection"
          value="No"
          checked={existingProtection === "No"}
          onChange={() => setExistingProtection("No")}
          style={{ marginRight: "4px" }}
        />
        No
      </label>
      &nbsp;&nbsp;Type:
    </h4>
  );
}

export default ExistingProtectionSelection;
