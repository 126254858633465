import React from "react";
import { useRouter } from "../routes/hooks/use-router";

const HomeTabContentCard = ({ item }) => {
  const router = useRouter();
  return (
    <div
      onClick={() =>
        router.push(
          item.status == "Not Resulted" ? "/leads" : "/leads?isView=true"
        )
      }
      className="k-rounded-lg k-elevation-1 k-p-4 k-mb-4 k-cursor-pointer"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <div>
        <h2 className="k-text-uppercase">{item.time}</h2>
        <h4 className="k-text-uppercase">Id: {item.id}</h4>
        <h4 className="k-text-uppercase">Code: {item.code}</h4>
      </div>
      <div className="k-text-end">
        <h4
          style={{ color: "#FF0000" }}
          className="k-m-0 k-pb-2 k-text-uppercase"
        >
          {item.wait}
        </h4>
        <h1 className="k-m-0 k-pb-2 k-font-normal k-text-uppercase">
          {item.name}
        </h1>
        <h2
          style={{
            color: item.status == "Not Resulted" ? "#FF0000" : "#00853E",
          }}
          className="k-m-0 k-pb-2 k-text-uppercase"
        >
          {item.status}
        </h2>
      </div>
    </div>
  );
};

export default HomeTabContentCard;
