import React from 'react'
import HomeTabContentCard from './home-tab-content-card'

const HomeTabsContent = () => {
    const array=[
        {
            time:"9:00 AM",
            id:"11945650",
            code:"41PQS",
            wait:"Waiting to Install!!!",
            name:"Thomas Razin",
            status:"Not Resulted",
        },
        {
            time:"11:00 AM",
            id:"11946475",
            code:"41a3scinb",
            wait:"Waiting to Install!!!",
            name:"brenda bayhan",
            status:"Resulted",
        },
        {
            time:"1:00 PM",
            id:"11945650",
            code:"41PQS",
            wait:"Waiting to Install!!!",
            name:"Angelique caro",
            status:"Resulted",
        },
        {
            time:"3:00 PM",
            id:"11945650",
            code:"41PQS",
            wait:"Waiting to Install!!!",
            name:"john d gray",
            status:"Not Resulted",
        },
        {
            time:"5:00 PM",
            id:"11945650",
            code:"41PQS",
            wait:"Waiting to Install!!!",
            name:"Murphy Smith",
            status:"Not Resulted",
        },
        
    ]
  return (
    <div className='k-rounded-md k-pt-4 k-px-4 k-pb-1 k-mb-4' style={{backgroundColor:"#8ABD5A"}}>
      {array.map((item)=><HomeTabContentCard item={item}/>) }
    </div>
  )
}

export default HomeTabsContent