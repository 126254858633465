import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
// import Modal from 'react-bootstrap/Modal';
import EntryCard from "./entry-card";
import scan from "../../assets/images/scan.png";
import mannual from "../../assets/images/manual.png";
import { IoCloseSharp } from "react-icons/io5";
import "./Dialog.scss";
import ReactDOM from "react-dom";

function Modal({ isOpen, onClose, children }) {
  const dialogRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dialogRef.current &&
      !dialogRef.current.contains(event.target) &&
      isOpen
    ) {
      onClose(false);
    }
  };

  const handleKeydown = (ev) => {
    if (ev.key === "Escape") {
      onClose(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeydown);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeydown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [isOpen]);

  let portalRoot = document.getElementById("modal");
  if (!portalRoot) {
    portalRoot = document.createElement("div");
    portalRoot.setAttribute("id", "modal");
    document.body.appendChild(portalRoot);
  }
  const displayClass = isOpen ? "show" : "hide";

  return ReactDOM.createPortal(
    <div id="modal-comp" className={displayClass} isOpen={isOpen}>
      <div className="dialog" ref={dialogRef} tabIndex={1}>
        {children}
      </div>
    </div>,
    portalRoot
  );
}

function Close(props) {
  return <IoCloseSharp className="close-svg" {...props} />;
}

const DialogSelect = ({ visible, toggleDialog }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 600);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const arr = [
    {
      image: scan,
      title: "Scan",
      path:"/scan"
    },
    {
      image: mannual,
      title: "Manual",
      path:"/preview"
    },
  ];
  return (
    <div className="container-dailog">
      <Modal isOpen={visible} onClose={() => toggleDialog()}>
        <div className="modal-title-container">
          <div className="modal-title"></div>
          <Close onClick={() => toggleDialog()} />
        </div>
        <div className="k-py-15">
          <div className="k-text-center">
            <h1 className="k-font-semibold">Choose Entry Method</h1>
            <h4 className="k-font-normal k-mt-4">
              Select an option to proceed with your document submission
            </h4>
          </div>
          <div
            className={`k-display-flex k-justify-content-center k-gap-20 k-mt-10 ${
              isSmallScreen ? "k-flex-col" : "k-flex-row"
            }`}
          >
            {arr.map((item) => (
              <EntryCard hide={toggleDialog} item={item} isSmallScreen={isSmallScreen} />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DialogSelect;
