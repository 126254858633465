import { Button } from '@progress/kendo-react-buttons'
import React from 'react'
import HomeTabsContent from './home-tabs-content'

const HomeTabs = () => {
  return (
    <div className='k-mt-5'>
      <Button
            primary={true}
            className=""
            size="large"
          >
            Previous Day
          </Button>
          <Button
            primary={true}
            className="k-button-red k-mx-2"
            size="large"
          >
            Today
          </Button>
          <Button
            primary={true}
            className=""
            size="large"
          >
            Tomorrow
          </Button>
          <HomeTabsContent/>
    </div>
  )
}

export default HomeTabs