import React from "react";
import Input from "../../components/preview-input/input";

const Story = () => {
  return (
    <div style={{  borderRight: "1px solid black", }}>
      {/* Header Row */}
      <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr", alignItems: "center",  textAlign: "center" }}>
        <h4 style={{ fontWeight: "bold" }}></h4>
        <h4>0.12 ft.</h4>
        <h4>12.1 ft.- 24ft.</h4>
        <h4>Over 24ft.</h4>
      </div>

      <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr", alignItems: "center",  textAlign: "center" }}>
        <h4>Story:</h4>
        <h4>1st Story</h4>
        <h4>2nd Story</h4>
        <h4>3rd Story</h4>
      </div>

      {/* Rows for each side */}
      {["Left Side of Home", "Right Side of Home", "Front of Home", "Rear of Home", "Garage", "Other"].map((label, index) => (
        <div key={index} style={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr", alignItems: "center",  textAlign: "center" }}>
          <h4 style={{ fontWeight: "normal", textAlign: "left" }}>{label}</h4>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ border: "1px solid black", padding: "5px", marginRight: "5px", textAlign: "center", width: "30px" }}>
              <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
                <Input />
              </div>
            </div>
            <div style={{ border: "1px solid black", padding: "5px", textAlign: "center", width: "30px" }}>
            <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
              <Input />
            </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {/* <Input /> */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ border: "1px solid black", padding: "5px", marginRight: "5px", textAlign: "center", width: "30px" }}>
              <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
                <Input />
              </div>
              </div>
              <div style={{ border: "1px solid black", padding: "5px", textAlign: "center", width: "30px" }}>
              <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
                <Input />
              </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {/* <Input /> */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ border: "1px solid black", padding: "5px", marginRight: "5px", textAlign: "center", width: "30px" }}>
              <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
                <Input />
              </div>
              </div>
              <div style={{ border: "1px solid black", padding: "5px", textAlign: "center", width: "30px" }}>
              <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
                <Input />
              </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Total Row */}
      <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr", alignItems: "center",  textAlign: "center", fontWeight: "bold" }}>
        <h4 style={{ textAlign: "left" }}>Total:</h4>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {/* <Input /> */}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ border: "1px solid black", padding: "5px", marginRight: "5px", textAlign: "center", width: "30px" }}>
            <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
              <Input />
            </div>
            </div>
            <div style={{ border: "1px solid black", padding: "5px", textAlign: "center", width: "30px" }}>
            <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
              <Input />
            </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {/* <Input /> */}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ border: "1px solid black", padding: "5px", marginRight: "5px", textAlign: "center", width: "30px" }}>
            <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
              <Input />
            </div>
            </div>
            <div style={{ border: "1px solid black", padding: "5px", textAlign: "center", width: "30px" }}>
            <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
              <Input />
            </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {/* <Input /> */}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ border: "1px solid black", padding: "5px", marginRight: "5px", textAlign: "center", width: "30px" }}>
            <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
              <Input />
            </div>
            </div>
            <div style={{ border: "1px solid black", padding: "5px", textAlign: "center", width: "30px" }}>
            <div className="k-pos-relative" style={{top:"6px" , right:"5px" , width:"36px"}}>
              <Input />
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
