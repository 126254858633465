import { Button } from "@progress/kendo-react-buttons";
import React, { useRef } from "react";
import Webcam from "react-webcam";
import scanButton from '../../assets/images/scan-button.png';
import { useRouter } from "../routes/hooks/use-router";
import styles from './Camra.module.scss';
// import jsQR from "jsqr";

const videoConstraints = {
  width: 540,
  facingMode: "environment"
};

const CameraCapture = () => {
  const webcamRef = useRef(null);
  const router=useRouter()
  const [url, setUrl] = React.useState(null);

  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
  }, [webcamRef]);

  const onUserMedia = (e) => {
    console.log(e);
  };

  return (
    <>
      {/* <Webcam
        ref={webcamRef}
        audio={true}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        onUserMedia={onUserMedia}
      />
      <button onClick={capturePhoto}>Capture</button>
      <button onClick={() => setUrl(null)}>Refresh</button>
      {url && (
        <div>
          <img src={url} alt="Screenshot" />
        </div>
      )} */}
    

     {url ? 
     <>
     <div
     className="k-my-10 k-rounded-lg k-p-4 k-d-flex k-justify-content-center"
  style={{ backgroundColor: "#F3F3F3", }}
     >
  <img src={url} alt="Screenshot"/>
     </div>
     <div className="k-w-full k-d-flex k-justify-content-end k-mb-8">
     <Button onClick={() => setUrl(null)} primary={true} className="k-button k-mr-2" size="large">
         Cancel
        </Button>
     <Button onClick={()=>router.push("/scan-preview")} primary={true} className="k-button-green k-mr-2" size="large">
         Next
        </Button>
     </div>
     </>
   
       :     
     <div>
      <h2 className="k-py-4 k-font-bold k-m-0">Scan</h2>
      <h4 className="k-font-light k-m-0">Please point the camera at the page</h4>
      <div
  className="k-my-10 k-rounded-lg k-p-4 k-d-flex k-justify-content-center"
  style={{ backgroundColor: "#F3F3F3", height: "100%", }}
>
  <div className={styles.corner_border}>
  <div className={styles.top_right}></div>
  <div className={styles.bottom_left}></div>
  <Webcam
  // style={{height:"95%",width:"94%",paddingLeft:"9px",paddingTop:"10px"}}
  className={styles.webcam}
        ref={webcamRef}
        audio={true}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        onUserMedia={onUserMedia}
      />
  <div style={{marginTop:"-30px"}} className="k-text-center">
  <img onClick={capturePhoto} height={50} src={scanButton} alt="button" style={{cursor:"pointer",position:"relative"}}/>
  </div>
  </div>
</div>

      </div>} 
    </>
  );
};

export default CameraCapture;
