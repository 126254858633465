import React from 'react'
import Input from '../../components/preview-input/input'

const MainDetails = () => {
  return (
   <>
       <div className='k-d-grid k-grid-cols-4 k-text-center k-gap-5 k-pt-4 k-pb-1 k-px-2'>
     <div className='k-display-flex k-gap-2'>
        <h2 className='k-m-0'>Customer</h2>
        <div className='k-pos-relative k-top-1'>
        <Input/>
        </div>
     </div>
     <div className='k-display-flex k-gap-2'>
        <h2 className='k-m-0'>City</h2>
        <div className='k-pos-relative k-top-1 k-w-full'>
        <Input/>
        </div>

     </div>
     <div className='k-display-flex k-gap-2'>
        <h2 className='k-m-0'>State</h2>
        <div className='k-pos-relative k-top-1 k-w-full'>
        <Input/>
        </div>

     </div>
     <div className='k-display-flex k-gap-2'>
        <h2 className='k-m-0'>Job#</h2>
        <div className='k-pos-relative k-top-1 k-w-full'>
        <Input/>
        </div>

     </div>
    </div>  

   </>

  )
}

export default MainDetails