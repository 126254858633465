import React, { useEffect, useState } from "react";
import SingleLeadTabContentCard from "./single-lead-tab-content-card";
import SingleLeadDetailCard from "./single-lead-detail-card";
import ActionsBar from "../../components/actions-bar/actions-bar";
import ResultLeadCard from "./result-lead-card";
import DocumentUpload from "./document-upload";
import DemoCard from "./demp-card";
import { useSearchParams } from "react-router-dom";

const SingleLeadTabContent = () => {
  const [searchParams] = useSearchParams();

  // Get the value of "isView" from query parameters
  const isView = searchParams.get("isView");
  const [enableEdit, setEnableEdit] = useState(isView);
  useEffect(() => {
    setEnableEdit(isView);
  }, [isView]);

  return (
    <div style={{ backgroundColor: "#FFFFFF", border: "1px solid #d3d3d3" }}>
      <div
        className="k-rounded-md k-pt-4 k-px-4 k-pb-1 k-m-2"
        style={{ backgroundColor: "#8ABD5A" }}
      >
        <SingleLeadTabContentCard />
        <ResultLeadCard
          enableEdit={enableEdit}
          update={() => setEnableEdit(!enableEdit)}
        />
        {enableEdit && <DemoCard 
        isView={isView}
        />}
        <SingleLeadDetailCard />
        {enableEdit && <DocumentUpload />}
      </div>
      <div
        className="k-p-8 k-mt-8 k-text-center"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <h2 className="k-text-uppercase k-font-normal">
          Finish making your changes to continue
        </h2>
      </div>
    </div>
  );
};

export default SingleLeadTabContent;
