import React, { useState } from 'react'
import Input from '../../components/preview-input/input'
import kStyle from '../../assets/images/k-style.png'
import halfRound from '../../assets/images/half-round.png'
import fascia from '../../assets/images/fascia.png'
const OrderProfile = () => {
   const [selectedProfile, setSelectedProfile] = useState("");
  return (
   <div className='k-d-grid k-grid-cols-3 k-text-center k-gap-20 k-pt-4 k-pb-1 k-px-2'>
   <div
     className='k-display-flex k-justify-content-between k-align-items-center k-gap-2'
     
   >
      <h2 className='k-m-0'>Gutter Profile:</h2>
  
     <div className='k-display-flex k-justify-content-center k-align-items-center k-gap-3' onClick={() => setSelectedProfile("K-style")}>
     <input
       type="radio"
       name="gutterProfile"
       value="K-style"
       checked={selectedProfile === "K-style"}
       onChange={() => setSelectedProfile("K-style")}
       className="k-mr-2"
     />
         <h2>K-style</h2>
       <div className='k-pos-relative k-top-1 k-left-10'>
         <img alt='k-style' src={kStyle} />
       </div>
     </div>
   </div>

   <div
     className='k-display-flex k-justify-content-center k-align-items-center k-gap-3'
     onClick={() => setSelectedProfile("Half-Round")}
   >
     <input
       type="radio"
       name="gutterProfile"
       value="Half-Round"
       checked={selectedProfile === "Half-Round"}
       onChange={() => setSelectedProfile("Half-Round")}
       className="k-mr-2"
     />
     <h2 className='k-m-0'>Half-Round</h2>
     <div className='k-pos-relative k-top-1 k-left-10'>
       <img alt='halfRound' src={halfRound} />
     </div>
   </div>

   <div
     className='k-display-flex k-justify-content-center k-align-items-center k-gap-3'
     onClick={() => setSelectedProfile("Fascia")}
   >
     <input
       type="radio"
       name="gutterProfile"
       value="Fascia"
       checked={selectedProfile === "Fascia"}
       onChange={() => setSelectedProfile("Fascia")}
       className="k-mr-2"
     />
     <h2 className='k-m-0'>Fascia</h2>
     <div className='k-pos-relative k-top-1 k-left-10'>
       <img alt='fascia' src={fascia} />
     </div>
   </div>
 </div>
  )
}

export default OrderProfile