import { useState } from "react";
import Input from "../../components/preview-input/input";

function RoofingTypeSelection() {
  const [selectedRoofing, setSelectedRoofing] = useState("Asphalt Shingle");

  return (
    <div className="k-display-flex k-mt-1">
      <div className="k-pos-relative k-top-1" style={{ width: "100px" }}>
        <Input />
      </div>

      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4">
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="roofing"
            value="Asphalt Shingle"
            checked={selectedRoofing === "Asphalt Shingle"}
            onChange={() => setSelectedRoofing("Asphalt Shingle")}
            style={{ marginRight: "10px" }}
          />
          Asphalt Shingle
        </label>
        <label>
          <input
            type="radio"
            name="roofing"
            value="Metal"
            checked={selectedRoofing === "Metal"}
            onChange={() => setSelectedRoofing("Metal")}
            style={{ marginRight: "10px" }}
          />
          Metal
        </label>
        <label style={{ marginRight:"9px",marginLeft:"10px" }}>

          <input
            type="radio"
            name="roofing"
            value="Tile"
            checked={selectedRoofing === "Tile"}
            onChange={() => setSelectedRoofing("Tile")}
            style={{ marginRight: "10px" }}
          />
          Tile
        </label>
        <label style={{ marginRight:"9px",marginLeft:"10px" }}>
          <input
            type="radio"
            name="roofing"
            value="Slate"
            checked={selectedRoofing === "Slate"}
            onChange={() => setSelectedRoofing("Slate")}
            style={{ marginRight: "10px" }}
          />
          Slate
        </label>
      </h4>
    </div>
  );
}

export default RoofingTypeSelection;
