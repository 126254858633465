import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import React from "react";

const SingleLeadToolBar = () => {
  return (
    <div>
      <h3>LEADS FOR:</h3>
      <h1>Tuesday 10/15/2024</h1>
    <div className="k-display-flex">
    <div className="k-mb-6">
        <Button
          primary={true}
          className="k-button-blue"
          size="large"
        >
          Lead Summary
        </Button>
      </div>
      <div className="k-mb-6 k-mx-3">
        <Button
          primary={true}
          className="k-button-blue"
          size="large"
        >
          Refresh Leads
        </Button>
      </div>
    </div>
    </div>
  );
};

export default SingleLeadToolBar;
