import { useState } from "react";
import Input from "../../components/preview-input/input";

function FlexGroundspoutSelection() {
  const [flexGroundspoutNeeded, setFlexGroundspoutNeeded] = useState("Yes");

  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">
        Flex. Groundspout Needed:&nbsp;
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="flexGroundspoutNeeded"
            value="Yes"
            checked={flexGroundspoutNeeded === "Yes"}
            onChange={() => setFlexGroundspoutNeeded("Yes")}
            style={{ marginRight: "8px" }}
          />
          Yes
        </label>
        <label style={{ marginLeft: "8px" }}>
          <input
            type="radio"
            name="flexGroundspoutNeeded"
            value="No"
            checked={flexGroundspoutNeeded === "No"}
            onChange={() => setFlexGroundspoutNeeded("No")}
            style={{ marginRight: "8px" }}
          />
          No
        </label>
        &nbsp; Units:
      </h4>

      <div className="k-pos-relative k-top-1 k-ml-4" style={{ width: "100px" }}>
        <Input />
      </div>
    </div>
  );
}

export default FlexGroundspoutSelection;
