import React from "react";

const Footer = () => {
  return (
    <div>
      <p className="k-font-medium k-text-uppercase" style={{lineHeight:"30px"}}>
        © 2024 LeafFilter North, LLC. All rights reserved. Trademarks:
        LeafFilter™, Leaf Filter™ and Gutter Protection Perfection™. This site
        is protected by reCAPTCHA and the Google <a className="k-text-no-underline" style={{color:"#337AB7"}} href="">Privacy Policy</a> and <a className="k-text-no-underline" style={{color:"#337AB7"}} href="">Terms of
        Service</a> <span className="k-pos-relative k-top-3 k-pl-1">apply</span>
      </p>
    </div>
  );
};

export default Footer;
