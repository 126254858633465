import { useState } from "react";

function ColorSelection() {
  const [selectedColor, setSelectedColor] = useState("White");

  return (
    <div className="k-d-grid k-grid-cols-5 k-mt-3 k-pr-4">
      <h4 className="k-m-0 k-font-light k-pt-2">Color:</h4>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        style={{marginRight:"10px"}}
          type="radio"
          name="color"
          value="White"
          checked={selectedColor === "White"}
          onChange={() => setSelectedColor("White")}
        />
        White
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        style={{marginRight:"10px"}}
          type="radio"
          name="color"
          value="Beige"
          checked={selectedColor === "Beige"}
          onChange={() => setSelectedColor("Beige")}
        />
        <span>
          Beige
        </span>
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        style={{marginRight:"10px"}}
          type="radio"
          name="color"
          value="Clay"
          checked={selectedColor === "Clay"}
          onChange={() => setSelectedColor("Clay")}
        />
        Clay
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        style={{marginRight:"10px"}}
          type="radio"
          name="color"
          value="Gray"
          checked={selectedColor === "Gray"}
          onChange={() => setSelectedColor("Gray")}
        />
        Gray
      </label>
    </div>
  );
}

export default ColorSelection;
