import React, { useState } from 'react';
import core from '../../assets/images/core.png';
import versa from '../../assets/images/versa.png';
import modular from '../../assets/images/modular.png';

const FilterProfile = () => {
  const [selectedFilterProfile, setselectedFilterProfile] = useState("Core");

  return (
    <div className='k-d-grid k-grid-cols-3 k-text-center k-gap-20 k-pt-4 k-pb-1 k-px-2'>
      <div className='k-display-flex k-justify-content-between k-align-items-center k-gap-2'>
        <h2 className='k-m-0'>LealFilter Profile</h2>

      {/* Core Profile */}
      <div
        className='k-display-flex k-justify-content-center k-align-items-center k-gap-3'
        onClick={() => setselectedFilterProfile("Core")}
      >
        <input
          type="radio"
          name="gutterProfile"
          value="Core"
          checked={selectedFilterProfile === "Core"}
          onChange={() => setselectedFilterProfile("Core")}
          className="k-mr-2"
        />
        <h2>Core</h2>
        <div className='k-pos-relative k-top-1 k-left-10 k-mb-3'>
          <img alt='core' src={core} />
        </div>
      </div>
      </div>


      {/* Modular Profile */}
      <div
        className='k-display-flex k-justify-content-center k-align-items-center k-gap-3'
        onClick={() => setselectedFilterProfile("Modular")}
      >
        <input
          type="radio"
          name="gutterProfile"
          value="Modular"
          checked={selectedFilterProfile === "Modular"}
          onChange={() => setselectedFilterProfile("Modular")}
          className="k-mr-2"
        />
        <h2 className='k-m-0'>Modular</h2>
        <div className='k-pos-relative k-top-1 k-left-10 k-mb-3'>
          <img alt='modular' src={modular} />
        </div>
      </div>

      {/* Versa Profile */}
      <div
        className='k-display-flex k-justify-content-center k-align-items-center k-gap-3'
        onClick={() => setselectedFilterProfile("Versa")}
      >
        <input
          type="radio"
          name="gutterProfile"
          value="Versa"
          checked={selectedFilterProfile === "Versa"}
          onChange={() => setselectedFilterProfile("Versa")}
          className="k-mr-2"
        />
        <h2 className='k-m-0'>Versa</h2>
        <div className='k-pos-relative k-top-1 k-left-10 k-mb-3'>
          <img alt='versa' src={versa} />
        </div>
      </div>
    </div>
  );
};

export default FilterProfile;
