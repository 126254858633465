import { useState } from "react";
import Input from "../../components/preview-input/input";

function LadderSelection() {
  const [selectedLadder, setSelectedLadder] = useState("8");

  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">
        Ladders:&nbsp;&nbsp;&nbsp;
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="ladder"
            value="8"
            checked={selectedLadder === "8"}
            onChange={() => setSelectedLadder("8")}
            style={{ marginRight: "10px" }}
          />
          8
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="ladder"
            value="10"
            checked={selectedLadder === "10"}
            onChange={() => setSelectedLadder("10")}
            style={{ marginRight: "10px" }}
          />
          10
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="ladder"
            value="24"
            checked={selectedLadder === "24"}
            onChange={() => setSelectedLadder("24")}
            style={{ marginRight: "10px" }}
          />
          24
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="ladder"
            value="28"
            checked={selectedLadder === "28"}
            onChange={() => setSelectedLadder("28")}
            style={{ marginRight: "10px" }}
          />
          28
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="ladder"
            value="32"
            checked={selectedLadder === "32"}
            onChange={() => setSelectedLadder("32")}
            style={{ marginRight: "10px" }}
          />
          32
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="ladder"
            value="40"
            checked={selectedLadder === "40"}
            onChange={() => setSelectedLadder("40")}
            style={{ marginRight: "10px" }}
          />
          40
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="ladder"
            value="other"
            checked={selectedLadder === "other"}
            onChange={() => setSelectedLadder("other")}
            style={{ marginRight: "10px" }}
          />
          other
        </label>
      </h4>

      {selectedLadder === "other" && (
        <div className="k-pos-relative k-top-1 k-ml-4" style={{ width: "100px" }}>
          <Input />
        </div>
      )}
    </div>
  );
}

export default LadderSelection;
