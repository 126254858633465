import { useState } from "react";
import Input from "../../components/preview-input/input";

function ExtensionsNeededSelection() {
  const [extensionsNeeded, setExtensionsNeeded] = useState("Yes");

  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">
        Extensions Needed:&nbsp;
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="extensionsNeeded"
            value="Yes"
            checked={extensionsNeeded === "Yes"}
            onChange={() => setExtensionsNeeded("Yes")}
            style={{ marginRight: "10px" }}
          />
          Yes
        </label>
        <label style={{ marginLeft: "8px" }}>
          <input
            type="radio"
            name="extensionsNeeded"
            value="No"
            checked={extensionsNeeded === "No"}
            onChange={() => setExtensionsNeeded("No")}
            style={{ marginRight: "10px" }}
          />
          No
        </label>
        &nbsp; Footage:
      </h4>

      <div className="k-pos-relative k-top-1 k-ml-4" style={{ width: "100px" }}>
        <Input />
      </div>

      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-3">
        FT.
      </h4>
    </div>
  );
}

export default ExtensionsNeededSelection;
