import React from "react";
import Input from "../../components/preview-input/input";
import Drawing from "./drawing";
import SoffitReplacementSelection from "./soffit-replacement-selection";
import usePreviewStore from "../../store/preview-store";
import PorchCeiling from "./porch-ceiling";

const Soffit = () => {
  const { previewData, setFormData } = usePreviewStore();

  const handleInputChange = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };
  return (
    <div
      className=" k-d-flex  k-border-solid k-border k-border-black k-mt-0"
      style={{ width: "100%" }}
    >
      <div
        className=" k-border-solid k-border k-border-black k-border-l-0 k-border-b-0 k-border-t-0 k-mt-0  "
        style={{ width: "60%" }}
      >
        <SoffitReplacementSelection />

        <div className="k-d-flex k-align-content-between k-grid-cols-1 k-mt-4">
          <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-30 k-px-2">
            Wood / Vinyl / Other:
          </h4>
          <div className="k-flex-basis-auto k-d-flex k-m-0 k-w-40  k-font-light k-mt-0">
            <Input
              value={previewData.soffit_other}
              onChange={handleInputChange("soffit_other")}
            />
          </div>
          <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-40 k-pl-13">
            Color:
          </h4>
          <div className="k-flex-basis-auto k-d-flex k-m-0 k-w-40  k-font-light k-mt-0 k-pl-8">
            <Input
              value={previewData.soffit_color}
              onChange={handleInputChange("soffit_color")}
            />
          </div>
        </div>

        <div className="k-d-flex k-justify-content-center k-mt-0 k-mb-0">
          <h4 className="k-text-center" style={{ fontStyle: "italic" }}>
            (Photos Required of Replacement Areas)
          </h4>
        </div>

        <div
          className=" k-border-solid k-border k-border-t-1 k-border-l-0 k-border-b-0 k-border-black"
          style={{ width: "100%", minHeight: "100px" }}
        >
          <div className="k-mt-0 k-py-4 k-m-0 k-px-2">
            <h4
              className="k-m-0 k-pb-2"
              style={{ textDecoration: "underline" }}
            >
              Drawing and Notes:
            </h4>
            <Drawing />
          </div>
        </div>
      </div>

      <PorchCeiling/>

      {/* Drawing and Notes Section */}
    </div>
  );
};

export default Soffit;
