import React from 'react'
import styles from './Input.module.scss'
const Input = ({value,onChange}) => {
  return (
    <input
    onChange={onChange}
    value={value}
    type="text"
    className={styles.input}
/>
  )
}

export default Input