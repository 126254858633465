import React from 'react'
import SingleLeadToolBar from './single-lead-toolbar'
import SingleLeadTabs from './single-lead-tabs'
import ActionsBar from '../../components/actions-bar/actions-bar'

const SingleLead = () => {
  return (
   <>
   <SingleLeadToolBar/>
   <SingleLeadTabs/>
   <div className='k-pt-2 k-pb-1 k-mb-1' style={{backgroundColor:"#FFFFFF"}}>
    <ActionsBar/>
    </div>
   </>
  )
}

export default SingleLead