import { useState } from "react";

function WalkboardSelection() {
  const [walkboardNeeded, setWalkboardNeeded] = useState("Yes");

  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">
        Walkboards:&nbsp;&nbsp;&nbsp;
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="walkboardNeeded"
            value="Yes"
            checked={walkboardNeeded === "Yes"}
            onChange={() => setWalkboardNeeded("Yes")}
            style={{ marginRight: "4px" }}
          />
          Yes
        </label>
        <label style={{ marginLeft: "8px" }}>
          <input
            type="radio"
            name="walkboardNeeded"
            value="No"
            checked={walkboardNeeded === "No"}
            onChange={() => setWalkboardNeeded("No")}
            style={{ marginRight: "4px" }}
          />
          No
        </label>
      </h4>
    </div>
  );
}

export default WalkboardSelection;
