import React from "react";
import HomeToolBar from "./home-toolbar";
import HomeTabs from "./home-tabs";
const Home = () => {
  return (
    <div>
      <HomeToolBar />
      <HomeTabs/>
    </div>
  );
};

export default Home;
