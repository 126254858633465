import { lazy, Suspense } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import SplashScreen from "../components/loading-screen/splash-screen";
import GuestGuard from "../auth/guard/guest-guard";
import AuthGuard from "../auth/guard/auth-guard";
import MainLayout from "../layouts/main/main";
import OrderSheet from "../pages/order-sheet/order-sheet";
// Lazy load the Home component for code-splitting
import Home from "../pages/home/home";
import Login from "../pages/auth/login/login";
import SingleLead from "../pages/single-leads/single-leads";
import Preview from "../pages/preview/preview";
import CameraCapture from "../pages/camera-capture/camera-capture";
// const NotFound = lazy(() => import('../pages/not-found/not-found')); // Uncomment if you have a NotFound page

function Layout() {
  return (
    <div>
      <Outlet /> {/* For nested routes if needed */}
    </div>
  );
}

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <Suspense>
          <AuthGuard>
            <Layout />
          </AuthGuard>
       </Suspense>
      ),
      children: [
        {
          path: "",
          element: (
            <MainLayout>
              <Home />
            </MainLayout>
          ),
        },
        {
          path: "leads",
          element: (
            <MainLayout>
              <SingleLead />
            </MainLayout>
          ),
        },
        {
          path: "preview",
          element: (
            <MainLayout>
              <Preview />
            </MainLayout>
          ),
        },
        {
          path: "scan",
          element: (
            <MainLayout footer={false}>
              <CameraCapture />
            </MainLayout>
          ),
        },
        {
          path: "scan-preview",
          element: (
            <MainLayout footer={false}>
              <OrderSheet />
            </MainLayout>
          ),
        },
      ],
    },
    {
      path: "auth/login",
      element: (
        <Suspense fallback={<SplashScreen />}>
          <GuestGuard>
            <Login />
          </GuestGuard>
        </Suspense>
      ),
    },
    {
      path: "/404",
      element: (
        <Suspense fallback={<SplashScreen />}>{/* <NotFound /> */}</Suspense>
      ),
    },
    {
      path: "*", // Catch-all for undefined routes
      element: <Navigate to="/404" replace />,
    },
  ]);
}
