export const previewValues={
    customer: '',
    city: '',
    st: '',
    job: '',
    measurements: Array(7).fill({
      firstStory: "",
      secondStory: "",
      thirdStory: ""
    }),
    roof_type:'',
    color:'',
    gutter_type:'FS',
    gutter_size:'4',
    total_gutter_footage:'',
    downspout:'2x3',
    downspout_color:'',
    downspout_footage:'',
    aelbows:'',
    belbows:'',
    roundElbows:'',
    offsetElbows:'',
    gutter_downspout_elbows:'',
    outside:'',
    inside:'',
    rights:'',
    lefts:'',
    savers:'',
    straps:'',
    wedges:'',
    partial_install:'Yes',
    fascia_replacement:'Full',
    fascia_total_footage:'',
    fascia_size:'1x4',
    fascia_other_size:'',
    fascia_type:'',
    fascia_other:'',
    fascia_wrap:'',
    soffit_replacement:'Full',
    soffit_total_footage:'',
    soffit_other:'',
    soffit_color:'',
    porch_ceiling: Array(5).fill({
      location: "",
      length: "",
      width: "",
      sq_ft: ""
    })
}