import React, { useState } from "react";
import Input from "../../components/preview-input/input";
import DownspoutSelection from "./downspout-selection";
import GutterSizeSelection from "./gutter-size-selection";
import GutterTypeSelection from "./gutter-type-selection";
import PartialInstallSelection from "./partial-install-selection";
import usePreviewStore from "../../store/preview-store";
import DownspoutDetails from "./downspout-details";
import TotalFootageDetails from "./total-footage-details";

const MeasurementsRight = () => {
  const { previewData, setFormData } = usePreviewStore();

  const handleInputChange = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };

  return (
    <div className="k-pl-6 k-pb-4 k-pt-2">
      <div className="k-display-flex k-gap-2">
        <h4 className="k-m-0 k-font-light k-pt-2">Roof Type :</h4>
        <div className="k-pos-relative k-top-1">
          <Input
            value={previewData.roof_type}
            onChange={handleInputChange("roof_type")}
          />
        </div>
      </div>
      <GutterTypeSelection />
      <GutterSizeSelection />

      <div className="k-display-flex k-gap-2 k-mt-2">
        <h4 className="k-m-0 k-font-light k-pt-2">Total Gutter Footage:</h4>
        <div className="k-pos-relative k-top-1">
          <Input
            value={previewData.total_gutter_footage}
            onChange={handleInputChange("total_gutter_footage")}
          />
        </div>
      </div>
      <DownspoutSelection />
      <DownspoutDetails />

      <div className="k-d-grid k-px-4 k-border-solid k-border k-border-black k-mt-3">
        <div className="k-display-flex k-gap-4">
          <h3 className="k-m-0 k-py-3">
            Total Footage (gutter + downspout + elbows):
          </h3>
          <div className="k-pos-relative k-top-1 k-top-3">
            <Input
              value={previewData.gutter_downspout_elbows}
              onChange={handleInputChange("gutter_downspout_elbows")}
            />
          </div>
        </div>
      </div>

      <TotalFootageDetails />

      <div className="k-d-grid k-grid-cols-2 k-mt-2 k-pr-4">
        <div className="k-d-grid k-grid-cols-2">
          <h4 className="k-m-0 k-font-light k-pt-2"># of Wedges:</h4>
          <div className="k-pos-relative k-top-1 k-right-3">
            <Input
              value={previewData.wedges}
              onChange={handleInputChange("wedges")}
            />
          </div>
        </div>
        <PartialInstallSelection />
      </div>
    </div>
  );
};

export default MeasurementsRight;
