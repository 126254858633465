import React from "react";
import Input from "../../components/preview-input/input";
import usePreviewStore from "../../store/preview-store";

const MeasurementsLeft = () => {
  const { previewData, setFormData } = usePreviewStore();

  const handleInputChange = (index, field) => (e) => {
    const newMeasurements = previewData.measurements.map((measurement, i) =>
      i === index ? { ...measurement, [field]: e.target.value } : measurement
    );
    setFormData({ measurements: newMeasurements });
  };

  const renderMeasurementRow = (label, index) => (
    <div className="k-d-grid k-grid-cols-4 k-mt-8">
      <h4 className="k-m-0 k-font-light">{label}</h4>
      <div style={{ width: "80%" }}>
        <Input
          value={previewData.measurements[index].firstStory}
          onChange={handleInputChange(index, "firstStory")}
        />
      </div>
      <div style={{ width: "80%" }}>
        <Input
          value={previewData.measurements[index].secondStory}
          onChange={handleInputChange(index, "secondStory")}
        />
      </div>
      <div style={{ width: "80%" }}>
        <Input
          value={previewData.measurements[index].thirdStory}
          onChange={handleInputChange(index, "thirdStory")}
        />
      </div>
    </div>
  );
  return (
    <div className="k-border-l-0 k-border-t-0 k-border-b-0 k-border-solid k-border k-border-black k-pl-6 k-pb-4 k-pt-4">
      <div className="k-d-grid k-grid-cols-4">
        <h4 className="k-font-light">Gutter Height:</h4>
        <h4>0-12 ft.</h4>
        <h4>12.1 ft.- 24ft.</h4>
        <h4>24ft.</h4>
      </div>

      <div className="k-d-grid k-grid-cols-4">
        <h4>Measurements:</h4>
        <h4>1st Story</h4>
        <h4>2nd Story</h4>
        <h4>3rd Story</h4>
      </div>

      {renderMeasurementRow("Left Side of Home:", 0)}
      {renderMeasurementRow("Right Side of Home:", 1)}
      {renderMeasurementRow("Front of Home:", 2)}
      {renderMeasurementRow("Rear of Home:", 3)}
      {renderMeasurementRow("Garage:", 4)}
      {renderMeasurementRow("Other:", 5)}
      {renderMeasurementRow("Total:", 6)}
    </div>
  );
};

export default MeasurementsLeft;
