import { useState } from "react";
import Input from "../../components/preview-input/input";

function FastenerSelection() {
  const [selectedFastener, setSelectedFastener] = useState("");

  return (
    <div className="k-display-flex k-mt-1">
      <div className="k-pos-relative k-top-1" style={{ width: "100px" }}>
        <Input />
      </div>
      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4">
        <label>
          <input
            type="radio"
            name="fastener"
            value="Hidden Hangers"
            checked={selectedFastener === "Hidden Hangers"}
            onChange={() => setSelectedFastener("Hidden Hangers")}
            style={{ marginRight: "10px" }}
          />
          Hidden Hangers
        </label>
        
        <label>
          <input
            type="radio"
            name="fastener"
            value="Spikes"
            checked={selectedFastener === "Spikes"}
            onChange={() => setSelectedFastener("Spikes")}
            style={{ marginRight: "10px" }}
          />
          Spikes
        </label>
        
        <label style={{ margin: "0 8px" }}>
          <input
            type="radio"
            name="fastener"
            value="T Straps"
            checked={selectedFastener === "T Straps"}
            onChange={() => setSelectedFastener("T Straps")}
            style={{ marginRight: "10px" }}
          />
          T Straps
        </label>
        
        <label style={{ margin: "0 8px" }}>
          <input
            type="radio"
            name="fastener"
            value="Wedges"
            checked={selectedFastener === "Wedges"}
            onChange={() => setSelectedFastener("Wedges")}
            style={{ marginRight: "10px" }}
          />
          Wedges
        </label>
        
        <label style={{ margin: "0 8px" }}>
          <input
            type="radio"
            name="fastener"
            value="Rival"
            checked={selectedFastener === "Rival"}
            onChange={() => setSelectedFastener("Rival")}
            style={{ marginRight: "10px" }}
          />
          Rival
        </label>
      </h4>
    </div>
  );
}

export default FastenerSelection;
