import { Button } from '@progress/kendo-react-buttons'
import React from 'react'
import ActionsBar from '../../components/actions-bar/actions-bar'
import SingleLeadTabContent from './single-lead-tabs-content'

const SingleLeadTabs = () => {
  return (
    <div className='k-mt-2'>
    <Button
          primary={true}
          className=""
          size="large"
        >
          Yesterday
        </Button>
        <Button
          primary={true}
          className="k-button-red k-mx-2"
          size="large"
        >
          Today
        </Button>
        <Button
          primary={true}
          className=""
          size="large"
        >
          Tomorrow
        </Button>
        <div className='k-mt-10'>
            <ActionsBar/>
        </div>
        <div className='k-mt-2'>
          <SingleLeadTabContent/>
        </div>
  </div>
  )
}

export default SingleLeadTabs