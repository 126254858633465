import React from 'react'
import Input from '../../components/preview-input/input'
import usePreviewStore from '../../store/preview-store';

const DownspoutDetails = () => {
  const { previewData, setFormData } = usePreviewStore();

    const handleInputChange = (field) => (e) => {
        setFormData({
          ...previewData,
          [field]: e.target.value
        });
      };
  return (
    <div>
          <div className="k-d-grid k-grid-cols-2 k-mt-2 k-pr-4">
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2">Downspout Color:</h4>
        <div className="k-pos-relative k-top-1 k-right-6">
          <Input value={previewData.downspout_color} onChange={handleInputChange('downspout_color')} />
        </div>
      </div>
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2">Downspout Footage</h4>
        <div className="k-pos-relative k-top-1">
          <Input value={previewData.downspout_footage} onChange={handleInputChange('downspout_footage')} />
        </div>
      </div>
    </div>
    <div className="k-d-grid k-grid-cols-2 k-mt-2 k-pr-4">
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2"># of a elbows (1=1ft):</h4>
        <div className="k-pos-relative k-top-1 k-right-2">
          <Input value={previewData.aelbows} onChange={handleInputChange('aelbows')} />
        </div>
      </div>
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2"># of B elbows:</h4>
        <div className="k-pos-relative k-top-1">
          <Input value={previewData.belbows} onChange={handleInputChange('belbows')} />
        </div>
      </div>
    </div>
    <div className="k-d-grid k-grid-cols-2 k-mt-2 k-pr-4">
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2"># of Round elbows:</h4>
        <div className="k-pos-relative k-top-1 k-right-6">
          <Input value={previewData.roundElbows} onChange={handleInputChange('roundElbows')} />
        </div>
      </div>
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2"># of Offset elbows:</h4>
        <div className="k-pos-relative k-top-1">
          <Input value={previewData.offsetElbows} onChange={handleInputChange('offsetElbows')} />
        </div>
      </div>
    </div>

    </div>
  )
}

export default DownspoutDetails