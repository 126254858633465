import { useState } from "react";
import Input from "../../components/preview-input/input";

function FastenerTypeSelection() {
  const [selectedFastenerType, setSelectedFastenerType] = useState("Straps");

  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2 k-display-flex" style={{flexWrap:"wrap"}}>
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="fastenerType"
            value="Straps"
            checked={selectedFastenerType === "Straps"}
            onChange={() => setSelectedFastenerType("Straps")}
            style={{ marginRight: "10px",marginTop:"10px" }}
          />
          Straps
        </label>
        <label style={{marginRight:"10px"}}>
          <input
            type="radio"
            name="fastenerType"
            value="Rival Bar"
            checked={selectedFastenerType === "Rival Bar"}
            onChange={() => setSelectedFastenerType("Rival Bar")}
            style={{ marginRight: "10px",marginTop:"10px" }}
          />
          Rival Bar
        </label>
        <label style={{marginRight:"10px"}}>
          <input
            type="radio"
            name="fastenerType"
            value="Sickle & Shank"
            checked={selectedFastenerType === "Sickle & Shank"}
            onChange={() => setSelectedFastenerType("Sickle & Shank")}
            style={{ marginRight: "10px",marginTop:"10px" }}
          />
          Sickle & Shank
        </label>
        <label style={{marginRight:"10px"}}>
          <input
            type="radio"
            name="fastenerType"
            value="Fascia Hanger"
            checked={selectedFastenerType === "Fascia Hanger"}
            onChange={() => setSelectedFastenerType("Fascia Hanger")}
            style={{ marginRight: "10px",marginTop:"10px"}}
          />
          Fascia Hanger
        </label>
        <label style={{marginRight:"10px"}}>
          <input
            type="radio"
            name="fastenerType"
            value="T-Wedge"
            checked={selectedFastenerType === "T-Wedge"}
            onChange={() => setSelectedFastenerType("T-Wedge")}
            style={{ marginRight: "10px",marginTop:"10px" }}
          />
          T-Wedge
        </label>
      </h4>
      
      <div className="k-pos-relative k-top-1 k-ml-4 k-mt-3" style={{ width: "100px" }}>
        <Input />
      </div>

      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-3 k-mt-3">
        FT.
      </h4>
    </div>
  );
}

export default FastenerTypeSelection;
