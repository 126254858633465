import { useState } from "react";

function PartialSelection() {
  const [isPartial, setIsPartial] = useState("Yes");

  return (
    <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4">
      (Partial: &nbsp;&nbsp;
      <label>
        <input
          type="radio"
          name="partial"
          value="Yes"
          checked={isPartial === "Yes"}
          onChange={() => setIsPartial("Yes")}
          style={{ marginRight: "4px" }}
        />
        Yes
      </label>
      <label style={{ marginLeft: "8px" }}>
        <input
          type="radio"
          name="partial"
          value="No"
          checked={isPartial === "No"}
          onChange={() => setIsPartial("No")}
          style={{ marginRight: "4px" }}
        />
        No
      </label>
      )
    </h4>
  );
}

export default PartialSelection;
