import React from 'react'
import Logo from '../logo/logo'
import { GiHamburgerMenu } from "react-icons/gi";
import { useRouter } from '../../pages/routes/hooks/use-router';
import { useAuthContext } from '../../auth/hooks';
const Header = () => {
  const router=useRouter()
  const { logout } = useAuthContext();

  return (
    <div className='k-py-4 k-px-10 k-elevation-3 k-display-flex k-justify-content-between' style={{backgroundColor:"#FFF"}}>
        <div className='k-display-flex'>
        <Logo/>
        <GiHamburgerMenu className='k-mt-3 k-mx-10 k-cursor-pointer'/>
        </div>
         <div className='k-mr-20'>
            <p className='k-m-0'>tbleaffilter@gmail.com</p>
            <p className='k-m-0 k-text-right k-pt-2 k-cursor-pointer' onClick={()=>{
              logout()
              router.push("/auth/login")}}>LOG OFF</p>
         </div>
    </div>
  )
}

export default Header