import React from "react";
import Input from "../../components/preview-input/input";

const InputRow = ({ label }) => (
  <div
    style={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
      alignItems: "center",
    }}
  >
    <h4 className="k-mt-1">{label})</h4>
    {Array.from({ length: 4 }).map((_, index) => (
      <h4 key={index} className="k-mt-1">
        <div className="k-pos-relative k-top-1" style={{ width: "100px" }}>
          <Input />
        </div>
      </h4>
    ))}
  </div>
);
export default InputRow;
