import { useState } from "react";
import Input from "../../components/preview-input/input";

function GutterOpeningWidthSelection() {
  const [selectedWidth, setSelectedWidth] = useState("4");

  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">Gutter Opening Width:</h4>
      
      <div className="k-display-flex k-gap-3 k-ml-2">
        <label className="k-m-0 k-font-light k-pt-2">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="4"
            checked={selectedWidth === "4"}
            onChange={() => setSelectedWidth("4")}
          />
          4
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="4.5"
            checked={selectedWidth === "4.5"}
            onChange={() => setSelectedWidth("4.5")}
          />
          4.5
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="5"
            checked={selectedWidth === "5"}
            onChange={() => setSelectedWidth("5")}
          />
          5
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="5.5"
            checked={selectedWidth === "5.5"}
            onChange={() => setSelectedWidth("5.5")}
          />
          5.5
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="6"
            checked={selectedWidth === "6"}
            onChange={() => setSelectedWidth("6")}
          />
          6
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="7"
            checked={selectedWidth === "7"}
            onChange={() => setSelectedWidth("7")}
          />
          7
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="8"
            checked={selectedWidth === "8"}
            onChange={() => setSelectedWidth("8")}
          />
          8
        </label>
      </div>

      <div className="k-pos-relative k-top-1 k-ml-4" style={{ width: "100px" }}>
        <Input />
      </div>
    </div>
  );
}

export default GutterOpeningWidthSelection;
