import React, { useState } from "react";
import styles from "./SingleLead.module.scss";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
const ResultLeadCard = ({ enableEdit, update }) => {
  const [selectedValue, setSelectedValue] = React.useState("mr");
  const handleChange = React.useCallback(
    (e) => {
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );
  return (
    <div
      className="k-rounded-lg k-elevation-1 k-p-4 k-mb-4 k-cursor-pointer"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <h2 className="k-font-normal k-m-0 k-pt-4 k-text-uppercase">
        Result Lead:
      </h2>

      <div className="k-d-grid k-grid-cols-2 k-gap-20 k-w-full k-mx-4 k-mt-5">
        <div>
          <Label className="k-font-bold k-mb-2">RESULT:</Label>
          <div>
            <DropDownList
              className={styles.dropdown}
              defaultValue="SELECT A RESULT"
              style={{
                width: enableEdit ? "100%" : "350px",
                border: enableEdit ? "" : "2px solid red",
              }}
              onChange={() => update()}
              data={[
                "Add OnSale",
                "Demo",
                "No Demo",
                "Not Hit",
                "Rehash",
                "Sale",
              ]}
              // defaultValue="Pizza"
            />
          </div>
        </div>
        {enableEdit && (
          <div className="k-mr-4">
            <Label className="k-font-bold">REASON:</Label>
            <div>
              <DropDownList
                className={styles.dropdown}
                defaultValue="SELECT A REASON"
                style={{
                  width: "100%",
                }}
                data={ [
                  "Can't Afford",
                  "Didn't Like",
                  "DIY",
                  "Lowes Demo",
                  "Lowes Pending Sale",
                  "Lowes Rehash",
                  "Moving",
                  "No Job",
                  "No Need / Want",
                  "No Snap Decision",
                  "Other Projects",
                  "Rental Property",
                  "Rushed - No Time",
                  "Shop Around",
                  "Talk to Spouse",
                  "Think About It",
                  "Too Much Money",
                  "Would Not Commit",
                  "Other (Explain)"
              ]}
                // defaultValue="Pizza"
              />
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="k-display-flex k-ml-4 k-my-8">
          <h4 className="k-m-0 k-pr-2">ONE LEG:</h4>
          <RadioButton
            name="group1"
            value="mr"
            checked={selectedValue === "mr"}
            label="MR."
            onChange={handleChange}
          />
          <RadioButton
            name="group1"
            value="mrs"
            checked={selectedValue === "mrs"}
            label="MRS"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ResultLeadCard;
