import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import styles from "./SingleLead.module.scss";
import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import DialogSelect from "../../components/dialog/dialog";
import { useRouter } from "../routes/hooks/use-router";

const DemoCard = ({isView}) => {
  const router=useRouter()

  const [visible,setVisible]=useState(false)
  return (
    <div
      className="k-rounded-lg k-elevation-1 k-p-4 k-mb-4 k-cursor-pointer"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <h2 className="k-font-light k-m-0 k-pt-4 k-text-uppercase">Demo Info:</h2>
      <div className="k-ml-2">
        <div className="k-mt-4">
          <Label className="k-font-bold">Last Price Quoted:</Label>
        </div>
        <Input style={{ width: "350px", marginTop: "10px" }} />
      </div>
      <div className="k-display-flex k-justify-content-end k-my-4">
        <Button onClick={()=>router.push("/preview")} primary={true} className="k-button-darkBlue k-mr-2" size="large">
          View Quote
        </Button>
        <Button onClick={()=>setVisible(true)} primary={true} className="k-button-darkBlue" size="large">
          Input form
        </Button>
      </div>
     {visible && <DialogSelect visible={visible} toggleDialog={()=>setVisible(false)}/>}
    </div>
  );
};

export default DemoCard;
