import { useState } from "react";
import Input from "../../components/preview-input/input";

function RoofTypeSelection() {
  const [selectedRoofType, setSelectedRoofType] = useState("roofType");

  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">
        <label style={{ marginRight: "8px" }}>
          <input
            type="radio"
            name="roofType"
            value="Flat Roof"
            checked={selectedRoofType === "Flat Roof"}
            onChange={() => setSelectedRoofType("Flat Roof")}
            style={{ marginRight: "10px" }}
          />
          Flat Roof
        </label>
        
        <label style={{ margin: "0 8px" }}>
          <input
            type="radio"
            name="roofType"
            value="Wood Shake"
            checked={selectedRoofType === "Wood Shake"}
            onChange={() => setSelectedRoofType("Wood Shake")}
            style={{ marginRight: "10px" }}
          />
          Wood Shake
        </label>
        <label style={{ margin: "0 8px" }}>
          <input
            type="radio"
            name="roofType"
            value="Other"
            checked={selectedRoofType === "Other"}
            onChange={() => setSelectedRoofType("Other")}
            style={{ marginRight: "10px" }}
          />
          Other
        </label>
      </h4>

      <div className="k-pos-relative k-top-1 k-ml-2" style={{ width: "100px" }}>
        <Input />
      </div>
    </div>
  );
}

export default RoofTypeSelection;
