import { useState } from "react";

function ModularVersaSelection() {
  const [selectedModularColor, setSelectedModularColor] = useState("White");

  return (
    <div className="k-d-grid k-grid-cols-5 k-mt-3 k-pr-4">
      <h4 className="k-m-0 k-font-light k-pt-2">Modular & Versa:</h4>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        style={{marginRight:"10px"}}
          type="radio"
          name="modularColor"
          value="White"
          checked={selectedModularColor === "White"}
          onChange={() => setSelectedModularColor("White")}
        />
        <span>White</span>
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        style={{marginRight:"10px"}}
          type="radio"
          name="modularColor"
          value="Clay"
          checked={selectedModularColor === "Clay"}
          onChange={() => setSelectedModularColor("Clay")}
        />
        Clay
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        style={{marginRight:"10px"}}
          type="radio"
          name="modularColor"
          value="Bronze"
          checked={selectedModularColor === "Bronze"}
          onChange={() => setSelectedModularColor("Bronze")}
        />
        Bronze
      </label>
    </div>
  );
}

export default ModularVersaSelection;
