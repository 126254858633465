import { create } from 'zustand';
import { previewValues } from './utils/preview-values';

const usePreviewStore = create((set) => ({
  previewData: previewValues,
  setFormData: (newFormData) => set((state) => ({
    previewData: { ...state.previewData, ...newFormData }
  })),
  reset: () => set(previewValues)
}));

export default usePreviewStore;
